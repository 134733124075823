export enum Routes {
	HOME = "/",
	TERMS = "/termeni",
	PRIVACY = "/politica&confidentialitate",
	FAQ = "/intrebari-frecvente",
	NOTFOUND = "/404",
	REGISTER = "/inregistrare",
	NOTIFICATIONS = "/notificari",
	PROFILE = "/profil",
	EDIT_PROFILE = "/profile/edit",
	LOGIN = "/autentificare",
	LOGOUT = "/logout",
	OFFERS = "/propuneri",
	PARTNERSHIPS = "/propuneri-cercetare",
	POLIJOBS = "/propuneri-polijobs",
	FACULTY_PROPOSALS = "/propuneri-facultati",
	COMPANY_PROPOSALS = "/propuneri-companii",
	APPLICANTS = "/aplicanti",
	USERS = "/utilizatori",
	REPORTS = "/rapoarte",
	CONTACTS = "/companii-contact",
	NEWSLETTER = "/newsletter",
	SETTINGS = "/settings",
	EVENTS = "/evenimente",
	STUDENTS_TABLE = "/tabel-studenti",
	MY_STUDENTS = "/studentii-mei",
	MY_INTERNSHIPS = "/aplicarile-mele",
	MY_SCHOLARSHIPS = "/bursele-mele",
	STUDENTS = "/studenti",
	STUDENT = "/student",
	STUDENT_PROFILE = "/profil-student",
	DIPLOMA_STUDENTS = "/studenti-diploma",
	PRACTICE_STUDENTS = "/studenti-practica",
	BACHELOR_THESIS = "/lucrare-licenta",
	RESEARCH_THESIS = "/tema-cercetare",
	INTERNSHIP = "/stagiu-practica",
	OTHER_POS = "/alte-pozitii",
	OLD_POS = "/inrolari-anterioare",
	UNIVERSITY_ACCORDS = "/acorduri-universitate",
	STUDENTS_FEEDBACK = "/students-feedback",
	PHD_FEEDBACK = "/phd-feedback",
	PROFESSOR_FEEDBACK = "/professor-feedback",
	COMPANIES_FEEDBACK = "/companies-feedback",
	FEEDBACK_FLAGS = "/feedback-flags-administration",
	ADMIN_FLAGS = "/flags-administration",
	ADMIN_ENROLLMENTS = "/admin-enrollments",
	MY_ACCOUNT = "/contul-meu",
}
