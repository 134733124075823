import { Breakpoint, Button, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { PoliJobsPotentialCandidateDTO } from "../../Api";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { studentIdSet } from "../Student/StudentSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { downloadFile } from "../../utils/downloadUtils";
import { downloadStudentCv } from "../../Requests/academic-user-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";

const PoliJobsRecommendationsTable = (props: {
	recommendations: PoliJobsPotentialCandidateDTO[] | undefined;
}) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	let locale = {
		emptyText: t("tableText.noPotentialCandidates"),
	};

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const downloadFail = (_err: any) => {
		openNotification(
			t("universityAccord.error"),
			t("universityAccord.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const columns = [
		{
			title: t("polijobs.name"),
			dataIndex: "candidateName",
			key: "candidateName",
			fixed: "left" as "left",
			render: (
				text: string | undefined,
				record: PoliJobsPotentialCandidateDTO
			) => (
				<a onClick={() => goToStudentProfile(record.candidateId)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("polijobs.faculty"),
			dataIndex:
				i18n.language === "ro"
					? "candidateFacultyNameRo"
					: "candidateFacultyNameEn",
			key:
				i18n.language === "ro"
					? "candidateFacultyNameRo"
					: "candidateFacultyNameEn",
		},
		{
			title: t("polijobs.studyProgram"),
			dataIndex: "candidateStudyProgram",
			key: "candidateStudyProgram",
		},
		{
			title: t("polijobs.year"),
			dataIndex: "candidateYear",
			key: "candidateYear",
			render: (
				_text: string | undefined,
				record: PoliJobsPotentialCandidateDTO
			) => t("account." + record.candidateYear),
		},
		{
			title: t("polijobs.email"),
			dataIndex: "candidateEmail",
			key: "candidateEmail",
		},
		{
			title: t("polijobs.phone"),
			dataIndex: "candidatePhone",
			key: "candidatePhone",
		},
		{
			title: t("polijobs.cv"),
			dataIndex: "candidateCvFileName",
			key: "candidateCvFileName",
			responsive: ["md"] as unknown as Breakpoint[],
			render: (
				_text: string | undefined,
				record: PoliJobsPotentialCandidateDTO
			) => (
				<Button
					title={"CV"}
					style={{
						backgroundColor: "transparent",
					}}
					onClick={() =>
						downloadFile(
							downloadStudentCv(record.candidateId!),
							t("documents.Cv") + "_" + record.candidateName
						).catch(downloadFail)
					}
				>
					<FontAwesomeIcon
						icon={solid("download")}
						style={{ fontSize: "15px" }}
					/>
				</Button>
			),
		},
		{
			title: t("polijobs.score"),
			dataIndex: "matchingPercentage",
			key: "matchingPercentage",
			fixed: "right" as "right",
			render: (
				_text: string | undefined,
				record: PoliJobsPotentialCandidateDTO
			) => `${record.matchingPercentage} %`,
		},
	];

	return (
		<div className="flex flex-col gap-4">
			<div className="px-4 pb-10 flex flex-col">
				<div className="flex flex-col gap-10">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<Typography.Title level={3} className="my-0">
							{props.recommendations?.length ?? 0} {t("filtering.results")}
						</Typography.Title>
					</div>

					<Table
						locale={locale}
						columns={columns}
						dataSource={props.recommendations ?? []}
						pagination={false}
						scroll={{ x: "calc(300px + 50%)", y: 420 }}
						rowKey={(record) => record.candidateId!}
					/>
				</div>
			</div>
		</div>
	);
};

export default PoliJobsRecommendationsTable;
