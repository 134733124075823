import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { internshipStatus } from "../../utils/utilFunctions";

const OfferActions = (props: { item: any }) => {
	const { t } = useTranslation();

	const IconText = ({
		icon,
		text,
		tooltipText,
	}: {
		icon: any;
		text: string;
		tooltipText: string;
	}) => (
		<Space title={tooltipText}>
			{icon}
			{text}
		</Space>
	);
	return (
		<>
			{props.item && (
				<div className="flex flex-wrap items-center w-full gap-2">
					{props.item.numberOfPositions !== 0 && (
						<IconText
							icon={<FontAwesomeIcon icon={solid("users")} />}
							text={
								props.item.numberOfPositions?.toString()
									? props.item.availableNumberOfPositions?.toString() === "1"
										? props.item.availableNumberOfPositions.toString() +
										  " " +
										  t("internships.seat")
										: props.item.availableNumberOfPositions.toString() +
										  " " +
										  t("internships.seats")
									: "N/A"
							}
							tooltipText={t("internships.freePosts")}
							key="list-TeamOutlined"
						/>
					)}

					<IconText
						icon={
							internshipStatus(
								props.item.availableFrom,
								props.item.availableTo
							) ? (
								<FontAwesomeIcon icon={solid("circle-check")} />
							) : (
								<FontAwesomeIcon icon={solid("circle-xmark")} />
							)
						}
						text={
							internshipStatus(props.item.availableFrom, props.item.availableTo)
								? t("internships.activeInternship")
								: t("internships.closedInternship")
						}
						tooltipText={t("internships.status")}
						key="list-CircleOutlined"
					/>

					{props.item.url && (
						<a href={props.item.url} target="_blank" rel="noopener noreferrer">
							<b>
								<IconText
									icon={
										<FontAwesomeIcon
											icon={solid("arrow-up-right-from-square")}
										/>
									}
									text={t("internships.internshipLink")}
									tooltipText={t("internships.internshipLinkDetails")}
									key="list-AppstoreOutlined"
								/>
							</b>
						</a>
					)}
				</div>
			)}
		</>
	);
};

export default OfferActions;
