/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum NotificationType {
	CompanyValidationNeeded = "CompanyValidationNeeded",
	AccordUploaded = "AccordUploaded",
	AccordValidationNeeded = "AccordValidationNeeded",
	AccordSignatureNeeded = "AccordSignatureNeeded",
	AccordFinalized = "AccordFinalized",
	RepresentativeNominated = "RepresentativeNominated",
	TutorNominated = "TutorNominated",
	SkillEndorsed = "SkillEndorsed",
	AppreciationReceived = "AppreciationReceived",
	InternshipValidationNeeded = "InternshipValidationNeeded",
	InternshipApproved = "InternshipApproved",
	InternshipRejected = "InternshipRejected",
	FacultyCoordinatorNominated = "FacultyCoordinatorNominated",
	FacultyCoordinatorAccepted = "FacultyCoordinatorAccepted",
	FacultyCoordinatorRejected = "FacultyCoordinatorRejected",
	FacultyCoordinatorDesignated = "FacultyCoordinatorDesignated",
	ProfessorJoinedInternship = "ProfessorJoinedInternship",
	ProfessorInternshipValidated = "ProfessorInternshipValidated",
	ProfessorInternshipInvalidated = "ProfessorInternshipInvalidated",
	InternshipChanged = "InternshipChanged",
	InternshipDeleted = "InternshipDeleted",
	InternshipStudentApplication = "InternshipStudentApplication",
	InternshipCompanyAcceptance = "InternshipCompanyAcceptance",
	InternshipProfessorAcceptance = "InternshipProfessorAcceptance",
	InternshipCompanyRejection = "InternshipCompanyRejection",
	InternshipProfessorRejection = "InternshipProfessorRejection",
	InternshipStudentAcceptance = "InternshipStudentAcceptance",
	InternshipStudentRejection = "InternshipStudentRejection",
	InternshipEnrollmentDeleted = "InternshipEnrollmentDeleted",
	ConventionAcknowledgementNeeded = "ConventionAcknowledgementNeeded",
	ConventionSignatureNeeded = "ConventionSignatureNeeded",
	ConventionValidated = "ConventionValidated",
	NotebookSignatureNeeded = "NotebookSignatureNeeded",
	NotebookValidationNeeded = "NotebookValidationNeeded",
	NotebookApproved = "NotebookApproved",
	NotebookRejected = "NotebookRejected",
	CertificateSignatureNeeded = "CertificateSignatureNeeded",
	CertificateValidationNeeded = "CertificateValidationNeeded",
	CertificateApproved = "CertificateApproved",
	CertificateRejected = "CertificateRejected",
	AccordSignatureReminder = "AccordSignatureReminder",
	AccordApproved = "AccordApproved",
	AccordRejected = "AccordRejected",
	SupervisorAssigned = "SupervisorAssigned",
	InternshipDocumentSignatureNeeded = "InternshipDocumentSignatureNeeded",
	FinalNotebookRejected = "FinalNotebookRejected",
	FinalCertificateRejected = "FinalCertificateRejected",
	PracticeProtocolAdded = "PracticeProtocolAdded",
	PracticeProtocolChanged = "PracticeProtocolChanged",
	PracticeProtocolRejected = "PracticeProtocolRejected",
	PracticeProtocolFinalized = "PracticeProtocolFinalized",
	InternshipEnrollment = "InternshipEnrollment",
	CompanyPracticeSupervisorDesignated = "CompanyPracticeSupervisorDesignated",
	CompanyPracticeSupervisorRemoved = "CompanyPracticeSupervisorRemoved",
	OfferPublished = "OfferPublished",
	OfferUpdated = "OfferUpdated",
	SupervisedStudyProgramAdded = "SupervisedStudyProgramAdded",
	SupervisedStudyProgramRemoved = "SupervisedStudyProgramRemoved",
	UpcomingExpiringOffer = "UpcomingExpiringOffer",
	ExpiredOffer = "ExpiredOffer",
	SupervisorUnassigned = "SupervisorUnassigned",
	CompanyAdminNominated = "CompanyAdminNominated",
	CompanyAdminRevoked = "CompanyAdminRevoked",
	PracticeOverdueMarked = "PracticeOverdueMarked",
	PracticeOverdueRemoved = "PracticeOverdueRemoved",
	EngagementTutorNominated = "EngagementTutorNominated",
	CompanyInternshipValidated = "CompanyInternshipValidated",
	CompanyInternshipInvalidated = "CompanyInternshipInvalidated",
	InternshipStudentRejectionAfterAccept = "InternshipStudentRejectionAfterAccept",
	NotebookUpdated = "NotebookUpdated",
	CertificateUpdated = "CertificateUpdated",
	PossibleInterestInResearchProposal = "PossibleInterestInResearchProposal",
	ResearchProposalNewProfessorApplicant = "ResearchProposalNewProfessorApplicant",
	ResearchProposalNewCompanyApplicant = "ResearchProposalNewCompanyApplicant",
	ResearchProposalByProfessorAccepted = "ResearchProposalByProfessorAccepted",
	ResearchProposalByCompanyAccepted = "ResearchProposalByCompanyAccepted",
	ResearchProposalByProfessorRejected = "ResearchProposalByProfessorRejected",
	ResearchProposalByCompanyRejected = "ResearchProposalByCompanyRejected",
	PossibleInterestInPoliJobsProposal = "PossibleInterestInPoliJobsProposal",
	PoliJobsProposalDeleted = "PoliJobsProposalDeleted",
	PoliJobsProposalNewApplicant = "PoliJobsProposalNewApplicant",
	PoliJobsProposalAccepted = "PoliJobsProposalAccepted",
	PoliJobsProposalRejected = "PoliJobsProposalRejected",
}

export function NotificationTypeFromJSON(json: any): NotificationType {
	return NotificationTypeFromJSONTyped(json, false);
}

export function NotificationTypeFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): NotificationType {
	return json as NotificationType;
}

export function NotificationTypeToJSON(value?: NotificationType | null): any {
	return value as any;
}
