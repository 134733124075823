/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	PoliJobsProposalEnrollmentStatus,
	PoliJobsProposalEnrollmentStatusFromJSON,
	PoliJobsProposalEnrollmentStatusFromJSONTyped,
	PoliJobsProposalEnrollmentStatusToJSON,
} from "./PoliJobsProposalEnrollmentStatus";
import {
	ResearchProposalEnrollmentType,
	ResearchProposalEnrollmentTypeFromJSON,
	ResearchProposalEnrollmentTypeFromJSONTyped,
	ResearchProposalEnrollmentTypeToJSON,
} from "./ResearchProposalEnrollmentType";

/**
 *
 * @export
 * @interface PoliJobsCandidateDTO
 */
export interface PoliJobsCandidateDTO {
	/**
	 *
	 * @type {ResearchProposalEnrollmentType}
	 * @memberof PoliJobsCandidateDTO
	 */
	enrollmentType?: ResearchProposalEnrollmentType;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	studentId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	poliJobsProposalId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly applicantId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly applicantName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly applicantEmail?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly companyName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly companyAvatar?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly companyLinkedInUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly companyContactPhone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly companyEmail?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly url?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly poliJobsProposalName?: string | null;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsCandidateDTO
	 */
	applicationDate?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly poliJobsProposalProposerId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly poliJobsProposalProposerName?: string | null;
	/**
	 *
	 * @type {PoliJobsProposalEnrollmentStatus}
	 * @memberof PoliJobsCandidateDTO
	 */
	status?: PoliJobsProposalEnrollmentStatus;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly poliJobsProposalEnrollmentId?: string;
	/**
	 *
	 * @type {number}
	 * @memberof PoliJobsCandidateDTO
	 */
	availablePositions?: number;
	/**
	 *
	 * @type {boolean}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly isEnrollmentViewed?: boolean;
}

export function PoliJobsCandidateDTOFromJSON(json: any): PoliJobsCandidateDTO {
	return PoliJobsCandidateDTOFromJSONTyped(json, false);
}

export function PoliJobsCandidateDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsCandidateDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		enrollmentType: !exists(json, "enrollmentType")
			? undefined
			: ResearchProposalEnrollmentTypeFromJSON(json["enrollmentType"]),
		studentId: !exists(json, "studentId") ? undefined : json["studentId"],
		poliJobsProposalId: !exists(json, "poliJobsProposalId")
			? undefined
			: json["poliJobsProposalId"],
		applicantId: !exists(json, "applicantId") ? undefined : json["applicantId"],
		applicantName: !exists(json, "applicantName")
			? undefined
			: json["applicantName"],
		applicantEmail: !exists(json, "applicantEmail")
			? undefined
			: json["applicantEmail"],
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		companyName: !exists(json, "companyName") ? undefined : json["companyName"],
		companyAvatar: !exists(json, "companyAvatar")
			? undefined
			: json["companyAvatar"],
		companyLinkedInUrl: !exists(json, "companyLinkedInUrl")
			? undefined
			: json["companyLinkedInUrl"],
		companyContactPhone: !exists(json, "companyContactPhone")
			? undefined
			: json["companyContactPhone"],
		companyEmail: !exists(json, "companyEmail")
			? undefined
			: json["companyEmail"],
		url: !exists(json, "url") ? undefined : json["url"],
		poliJobsProposalName: !exists(json, "poliJobsProposalName")
			? undefined
			: json["poliJobsProposalName"],
		applicationDate: !exists(json, "applicationDate")
			? undefined
			: new Date(json["applicationDate"]),
		poliJobsProposalProposerId: !exists(json, "poliJobsProposalProposerId")
			? undefined
			: json["poliJobsProposalProposerId"],
		poliJobsProposalProposerName: !exists(json, "poliJobsProposalProposerName")
			? undefined
			: json["poliJobsProposalProposerName"],
		status: !exists(json, "status")
			? undefined
			: PoliJobsProposalEnrollmentStatusFromJSON(json["status"]),
		poliJobsProposalEnrollmentId: !exists(json, "poliJobsProposalEnrollmentId")
			? undefined
			: json["poliJobsProposalEnrollmentId"],
		availablePositions: !exists(json, "availablePositions")
			? undefined
			: json["availablePositions"],
		isEnrollmentViewed: !exists(json, "isEnrollmentViewed")
			? undefined
			: json["isEnrollmentViewed"],
	};
}

export function PoliJobsCandidateDTOToJSON(
	value?: PoliJobsCandidateDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		enrollmentType: ResearchProposalEnrollmentTypeToJSON(value.enrollmentType),
		studentId: value.studentId,
		poliJobsProposalId: value.poliJobsProposalId,
		applicationDate:
			value.applicationDate === undefined
				? undefined
				: value.applicationDate.toISOString(),
		status: PoliJobsProposalEnrollmentStatusToJSON(value.status),
		availablePositions: value.availablePositions,
	};
}
