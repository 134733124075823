/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	PoliJobsCandidateDTOPagedResponse,
	PoliJobsCandidateDTOPagedResponseFromJSON,
	PoliJobsCandidateDTOPagedResponseToJSON,
	PoliJobsPotentialCandidateDTO,
	PoliJobsPotentialCandidateDTOFromJSON,
	PoliJobsPotentialCandidateDTOToJSON,
	PoliJobsProposalAcceptDTO,
	PoliJobsProposalAcceptDTOFromJSON,
	PoliJobsProposalAcceptDTOToJSON,
	PoliJobsProposalAddUpdateDTO,
	PoliJobsProposalAddUpdateDTOFromJSON,
	PoliJobsProposalAddUpdateDTOToJSON,
	PoliJobsProposalCandidateFiltersDTO,
	PoliJobsProposalCandidateFiltersDTOFromJSON,
	PoliJobsProposalCandidateFiltersDTOToJSON,
	PoliJobsProposalDTO,
	PoliJobsProposalDTOFromJSON,
	PoliJobsProposalDTOToJSON,
	PoliJobsProposalDTOPagedResponse,
	PoliJobsProposalDTOPagedResponseFromJSON,
	PoliJobsProposalDTOPagedResponseToJSON,
	PoliJobsProposalEnrollmentStatus,
	PoliJobsProposalEnrollmentStatusFromJSON,
	PoliJobsProposalEnrollmentStatusToJSON,
	RequestResponse,
	RequestResponseFromJSON,
	RequestResponseToJSON,
} from "../models";

export interface ApiPoliJobsProposalAcceptApplicationPostRequest {
	poliJobsProposalAcceptDTO?: PoliJobsProposalAcceptDTO;
}

export interface ApiPoliJobsProposalAddPostRequest {
	poliJobsProposalAddUpdateDTO?: PoliJobsProposalAddUpdateDTO;
}

export interface ApiPoliJobsProposalApplyPoliJobsProposalIdPostRequest {
	poliJobsProposalId: string;
}

export interface ApiPoliJobsProposalCanApplyPoliJobsProposalIdGetRequest {
	poliJobsProposalId: string;
}

export interface ApiPoliJobsProposalDeleteIdDeleteRequest {
	id: string;
}

export interface ApiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGetRequest {
	poliJobsProposalId: string;
}

export interface ApiPoliJobsProposalGenerateRecommendationsForProposalPoliJobsProposalIdPutRequest {
	poliJobsProposalId: string;
	poliJobsProposalCandidateFiltersDTO?: PoliJobsProposalCandidateFiltersDTO;
}

export interface ApiPoliJobsProposalGetAcceptedApplicationsGetRequest {
	namePattern?: string;
	poliJobsProposals?: Array<string>;
	enrollmentStatus?: Array<PoliJobsProposalEnrollmentStatus>;
	viewStatuses?: Array<string>;
	page?: number;
	pageSize?: number;
}

export interface ApiPoliJobsProposalGetAllGetRequest {
	page?: number;
	pageSize?: number;
}

export interface ApiPoliJobsProposalGetByIdIdGetRequest {
	id: string;
}

export interface ApiPoliJobsProposalGetRecommendationsForProposalPoliJobsProposalIdGetRequest {
	poliJobsProposalId: string;
}

export interface ApiPoliJobsProposalRejectApplicationPostRequest {
	poliJobsProposalAcceptDTO?: PoliJobsProposalAcceptDTO;
}

export interface ApiPoliJobsProposalUpdateIdPutRequest {
	id: string;
	poliJobsProposalAddUpdateDTO?: PoliJobsProposalAddUpdateDTO;
}

export interface ApiPoliJobsProposalUpdateIsEnrollmentViewedIdPutRequest {
	id: string;
	isEnrollmentView?: boolean;
}

export interface ApiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPostRequest {
	poliJobsProposalId: string;
	file?: Blob;
}

/**
 *
 */
export class PoliJobsProposalApi extends runtime.BaseAPI {
	/**
	 */
	async apiPoliJobsProposalAcceptApplicationPostRaw(
		requestParameters: ApiPoliJobsProposalAcceptApplicationPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/AcceptApplication`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: PoliJobsProposalAcceptDTOToJSON(
					requestParameters.poliJobsProposalAcceptDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalAcceptApplicationPost(
		requestParameters: ApiPoliJobsProposalAcceptApplicationPostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalAcceptApplicationPostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsProposalAddPostRaw(
		requestParameters: ApiPoliJobsProposalAddPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/Add`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: PoliJobsProposalAddUpdateDTOToJSON(
					requestParameters.poliJobsProposalAddUpdateDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalAddPost(
		requestParameters: ApiPoliJobsProposalAddPostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalAddPostRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async apiPoliJobsProposalApplyPoliJobsProposalIdPostRaw(
		requestParameters: ApiPoliJobsProposalApplyPoliJobsProposalIdPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsProposalApplyPoliJobsProposalIdPost."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/Apply/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiPoliJobsProposalApplyPoliJobsProposalIdPost(
		requestParameters: ApiPoliJobsProposalApplyPoliJobsProposalIdPostRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response =
			await this.apiPoliJobsProposalApplyPoliJobsProposalIdPostRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalCanApplyPoliJobsProposalIdGetRaw(
		requestParameters: ApiPoliJobsProposalCanApplyPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsProposalCanApplyPoliJobsProposalIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/CanApply/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiPoliJobsProposalCanApplyPoliJobsProposalIdGet(
		requestParameters: ApiPoliJobsProposalCanApplyPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response =
			await this.apiPoliJobsProposalCanApplyPoliJobsProposalIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalDeleteIdDeleteRaw(
		requestParameters: ApiPoliJobsProposalDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiPoliJobsProposalDeleteIdDelete."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/Delete/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalDeleteIdDelete(
		requestParameters: ApiPoliJobsProposalDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalDeleteIdDeleteRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGetRaw(
		requestParameters: ApiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Blob>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/DownloadJobDescription/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.BlobApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGet(
		requestParameters: ApiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<Blob> {
		const response =
			await this.apiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalGenerateRecommendationsForProposalPoliJobsProposalIdPutRaw(
		requestParameters: ApiPoliJobsProposalGenerateRecommendationsForProposalPoliJobsProposalIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsProposalGenerateRecommendationsForProposalPoliJobsProposalIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/GenerateRecommendationsForProposal/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: PoliJobsProposalCandidateFiltersDTOToJSON(
					requestParameters.poliJobsProposalCandidateFiltersDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalGenerateRecommendationsForProposalPoliJobsProposalIdPut(
		requestParameters: ApiPoliJobsProposalGenerateRecommendationsForProposalPoliJobsProposalIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalGenerateRecommendationsForProposalPoliJobsProposalIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsProposalGetAcceptedApplicationsGetRaw(
		requestParameters: ApiPoliJobsProposalGetAcceptedApplicationsGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<PoliJobsCandidateDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.namePattern !== undefined) {
			queryParameters["NamePattern"] = requestParameters.namePattern;
		}

		if (requestParameters.poliJobsProposals) {
			queryParameters["PoliJobsProposals"] =
				requestParameters.poliJobsProposals;
		}

		if (requestParameters.enrollmentStatus) {
			queryParameters["EnrollmentStatus"] = requestParameters.enrollmentStatus;
		}

		if (requestParameters.viewStatuses) {
			queryParameters["ViewStatuses"] = requestParameters.viewStatuses;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/GetAcceptedApplications`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			PoliJobsCandidateDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiPoliJobsProposalGetAcceptedApplicationsGet(
		requestParameters: ApiPoliJobsProposalGetAcceptedApplicationsGetRequest = {},
		initOverrides?: RequestInit
	): Promise<PoliJobsCandidateDTOPagedResponse> {
		const response =
			await this.apiPoliJobsProposalGetAcceptedApplicationsGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalGetAllGetRaw(
		requestParameters: ApiPoliJobsProposalGetAllGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<PoliJobsProposalDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/GetAll`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			PoliJobsProposalDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiPoliJobsProposalGetAllGet(
		requestParameters: ApiPoliJobsProposalGetAllGetRequest = {},
		initOverrides?: RequestInit
	): Promise<PoliJobsProposalDTOPagedResponse> {
		const response = await this.apiPoliJobsProposalGetAllGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalGetByIdIdGetRaw(
		requestParameters: ApiPoliJobsProposalGetByIdIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<PoliJobsProposalDTO>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiPoliJobsProposalGetByIdIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/GetById/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			PoliJobsProposalDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiPoliJobsProposalGetByIdIdGet(
		requestParameters: ApiPoliJobsProposalGetByIdIdGetRequest,
		initOverrides?: RequestInit
	): Promise<PoliJobsProposalDTO> {
		const response = await this.apiPoliJobsProposalGetByIdIdGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalGetRecommendationsForProposalPoliJobsProposalIdGetRaw(
		requestParameters: ApiPoliJobsProposalGetRecommendationsForProposalPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<PoliJobsPotentialCandidateDTO>>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsProposalGetRecommendationsForProposalPoliJobsProposalIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/GetRecommendationsForProposal/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(PoliJobsPotentialCandidateDTOFromJSON)
		);
	}

	/**
	 */
	async apiPoliJobsProposalGetRecommendationsForProposalPoliJobsProposalIdGet(
		requestParameters: ApiPoliJobsProposalGetRecommendationsForProposalPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<Array<PoliJobsPotentialCandidateDTO>> {
		const response =
			await this.apiPoliJobsProposalGetRecommendationsForProposalPoliJobsProposalIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalRejectApplicationPostRaw(
		requestParameters: ApiPoliJobsProposalRejectApplicationPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/RejectApplication`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: PoliJobsProposalAcceptDTOToJSON(
					requestParameters.poliJobsProposalAcceptDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalRejectApplicationPost(
		requestParameters: ApiPoliJobsProposalRejectApplicationPostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalRejectApplicationPostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsProposalUpdateIdPutRaw(
		requestParameters: ApiPoliJobsProposalUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiPoliJobsProposalUpdateIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/Update/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: PoliJobsProposalAddUpdateDTOToJSON(
					requestParameters.poliJobsProposalAddUpdateDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalUpdateIdPut(
		requestParameters: ApiPoliJobsProposalUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalUpdateIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsProposalUpdateIsEnrollmentViewedIdPutRaw(
		requestParameters: ApiPoliJobsProposalUpdateIsEnrollmentViewedIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiPoliJobsProposalUpdateIsEnrollmentViewedIdPut."
			);
		}

		const queryParameters: any = {};

		if (requestParameters.isEnrollmentView !== undefined) {
			queryParameters["isEnrollmentView"] = requestParameters.isEnrollmentView;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/UpdateIsEnrollmentViewed/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalUpdateIsEnrollmentViewedIdPut(
		requestParameters: ApiPoliJobsProposalUpdateIsEnrollmentViewedIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalUpdateIsEnrollmentViewedIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPostRaw(
		requestParameters: ApiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPost."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const consumes: runtime.Consume[] = [
			{ contentType: "multipart/form-data" },
		];
		// @ts-ignore: canConsumeForm may be unused
		const canConsumeForm = runtime.canConsumeForm(consumes);

		let formParams: { append(param: string, value: any): any };
		let useForm = false;
		// use FormData to transmit files using content-type "multipart/form-data"
		useForm = canConsumeForm;
		if (useForm) {
			formParams = new FormData();
		} else {
			formParams = new URLSearchParams();
		}

		if (requestParameters.file !== undefined) {
			formParams.append("File", requestParameters.file as any);
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/UploadJobsDescription/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: formParams,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPost(
		requestParameters: ApiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPostRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPostRaw(
			requestParameters,
			initOverrides
		);
	}
}
