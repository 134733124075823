import { Button, Pagination, Typography } from "antd";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { InternshipSortFieldEnum, SortDirectionEnum } from "../../Api";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import OffersList from "./OffersList";
import { getMyPoliJobsOffers } from "../../utils/reactQueriesConstants";
import { PlusCircleOutlined } from "@ant-design/icons";
import useQueryFilters from "../../Hooks/useQueryFilters";
import { getAllPoliJobsProposals } from "../../Requests/polijobs-proposals-requests";

const PoliJobsOffers = (props: any) => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortField, setSortField] = useState(
		InternshipSortFieldEnum.PublishDate
	);
	const [sortDirection, setSortDirection] = useState(
		SortDirectionEnum.Descending
	);
	const queryClient = useQueryClient();
	const query = useQueryFilters({});

	const handleListChange = async (current: number, pageSize: number) => {
		setCurrentPage(() => current);
		setPageSize(() => pageSize);
		await queryClient.invalidateQueries(getMyPoliJobsOffers);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDatasError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: myOffers, isLoading: loadingMyOffers } = useQuery(
		[getMyPoliJobsOffers, query.filters],
		() => getAllPoliJobsProposals(query.filters),
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="px-4 pb-10 flex flex-col relative">
			<div className="fixed bottom-10 md:relative md:bottom-auto md:self-end md:mt-10 right-10 z-50">
				<Button
					type="primary"
					className="p-6 flex flex-col md:flex-row justify-center items-center rounded-full md:rounded-md relative"
				>
					<PlusCircleOutlined />

					<Link
						to={{
							pathname: "/editare-propunere-polijobs/",
						}}
					>
						{t("internships.addInternship")}
					</Link>
				</Button>
			</div>

			<div className="relative flex flex-col md:flex-row justify-between items-center mt-10">
				<Typography.Title level={3} className="my-0">
					{myOffers?.totalCount} {t("filtering.results")}
				</Typography.Title>

				<Pagination
					defaultCurrent={myOffers?.page}
					total={myOffers?.totalCount}
					onChange={onPageUpdate}
					pageSizeOptions={[12, 24, 48, 96]}
					defaultPageSize={12}
				/>
			</div>

			<div className="relative">
				<OffersList
					data={myOffers}
					checkedKeys={[]}
					loading={loadingMyOffers}
					location={props.location}
					activeTab={"1"}
					currentPage={currentPage}
					pageSize={pageSize}
					searchTerm={""}
					sortDirection={sortDirection}
					sortField={sortField}
					handleListChange={handleListChange}
				/>
			</div>
		</div>
	);
};

export default PoliJobsOffers;
