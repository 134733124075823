/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface PoliJobsProposalCandidateFiltersDTO
 */
export interface PoliJobsProposalCandidateFiltersDTO {
	/**
	 *
	 * @type {Array<string>}
	 * @memberof PoliJobsProposalCandidateFiltersDTO
	 */
	facultyIds?: Array<string> | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof PoliJobsProposalCandidateFiltersDTO
	 */
	studyProgramIds?: Array<string> | null;
	/**
	 *
	 * @type {Array<StudentYearEnum>}
	 * @memberof PoliJobsProposalCandidateFiltersDTO
	 */
	years?: Array<StudentYearEnum> | null;
}

export function PoliJobsProposalCandidateFiltersDTOFromJSON(
	json: any
): PoliJobsProposalCandidateFiltersDTO {
	return PoliJobsProposalCandidateFiltersDTOFromJSONTyped(json, false);
}

export function PoliJobsProposalCandidateFiltersDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsProposalCandidateFiltersDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		facultyIds: !exists(json, "facultyIds") ? undefined : json["facultyIds"],
		studyProgramIds: !exists(json, "studyProgramIds")
			? undefined
			: json["studyProgramIds"],
		years: !exists(json, "years")
			? undefined
			: json["years"] === null
			? null
			: (json["years"] as Array<any>).map(StudentYearEnumFromJSON),
	};
}

export function PoliJobsProposalCandidateFiltersDTOToJSON(
	value?: PoliJobsProposalCandidateFiltersDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		facultyIds: value.facultyIds,
		studyProgramIds: value.studyProgramIds,
		years:
			value.years === undefined
				? undefined
				: value.years === null
				? null
				: (value.years as Array<any>).map(StudentYearEnumToJSON),
	};
}
