import { CompanySelectProps } from "./CompanySelect.types";
import { Form, Select, Spin } from "antd";
import styles from "../../../AddInternshipForm.module.scss";
import { useTranslation } from "react-i18next";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";
import { useQuery } from "react-query";
import { getAllCompanies } from "../../../../../../utils/reactQueriesConstants";
import { getCompanies } from "../../../../../../Requests/company-requests";

const { Option } = Select;

export const CompanySelect = (props: CompanySelectProps) => {
	const { t } = useTranslation();

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: companies, isLoading: isLoadingCompanies } = useQuery(
		[
			getAllCompanies,
			props.isFacultyAdmin,
			props.isPoliJobsAdmin,
			props.debouncedCompanySearch,
		],
		async () => {
			if (!props.isFacultyAdmin && !props.isPoliJobsAdmin) {
				return null;
			}

			return getCompanies(props.debouncedCompanySearch, 1, 25).then((e) =>
				e.data?.map((e) => {
					return { id: e.id, name: e.commercialName };
				})
			);
		},
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const handleSelectedCompany = (selected: string) => {
		props.form.setFieldsValue({
			...props.form.getFieldsValue(),
			company: selected,
			engagementType: undefined,
		} as any);
	};

	return (
		<Form.Item
			required={props.required ?? false}
			name="company"
			label={t("internships.addInternshipForm.company") + ":"}
			style={{ marginTop: "-10px" }}
			rules={[props.yupSync]}
		>
			<Select
				className={styles.antSelect}
				allowClear
				showSearch
				style={{ width: "100%", textAlign: "left" }}
				filterOption={false}
				disabled={props.isEditing}
				onSearch={props.setCompanySearch}
				placeholder={t("internships.addInternshipForm.company")}
				onChange={handleSelectedCompany}
				notFoundContent={
					isLoadingCompanies ? (
						<Spin size="small" />
					) : (
						t("internships.addInternshipForm.notFoundContent")
					)
				}
			>
				{companies?.map((company) => (
					<Option key={company.id} value={company.id}>
						{company.name}
					</Option>
				))}
			</Select>
		</Form.Item>
	);
};
