/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PoliJobsProposalEnrollmentStatus {
	WaitingForProposer = "WaitingForProposer",
	Accepted = "Accepted",
	Rejected = "Rejected",
}

export function PoliJobsProposalEnrollmentStatusFromJSON(
	json: any
): PoliJobsProposalEnrollmentStatus {
	return PoliJobsProposalEnrollmentStatusFromJSONTyped(json, false);
}

export function PoliJobsProposalEnrollmentStatusFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsProposalEnrollmentStatus {
	return json as PoliJobsProposalEnrollmentStatus;
}

export function PoliJobsProposalEnrollmentStatusToJSON(
	value?: PoliJobsProposalEnrollmentStatus | null
): any {
	return value as any;
}
