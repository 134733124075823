import {
	PoliJobsProposalAddUpdateDTO,
	PoliJobsProposalApi,
	PoliJobsProposalCandidateFiltersDTO,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthPoliJobsProposalApiFactory = () =>
	new PoliJobsProposalApi(getAuthConfiguration());

export const addPoliJobsProposal = async (
	poliJobsProposalAddUpdateDTO: PoliJobsProposalAddUpdateDTO
) => {
	await AuthPoliJobsProposalApiFactory().apiPoliJobsProposalAddPost({
		poliJobsProposalAddUpdateDTO,
	});
};

interface AllPoliJobsProposalsProps {
	page?: number;
	pageSize?: number;
}

export const getAllPoliJobsProposals = (props: AllPoliJobsProposalsProps) => {
	const { page, pageSize } = props;
	return AuthPoliJobsProposalApiFactory().apiPoliJobsProposalGetAllGet({
		page,
		pageSize,
	});
};

export const getPoliJobsProposalById = (id: string) => {
	return AuthPoliJobsProposalApiFactory().apiPoliJobsProposalGetByIdIdGet({
		id,
	});
};

export const getRecommendationsForPoliJobsProposal = (
	poliJobsProposalId: string
) => {
	return AuthPoliJobsProposalApiFactory().apiPoliJobsProposalGetRecommendationsForProposalPoliJobsProposalIdGet(
		{
			poliJobsProposalId,
		}
	);
};

export const generateRecommendationsForPoliJobsProposal = (
	poliJobsProposalId: string,
	poliJobsProposalCandidateFiltersDTO: PoliJobsProposalCandidateFiltersDTO
) => {
	return AuthPoliJobsProposalApiFactory().apiPoliJobsProposalGenerateRecommendationsForProposalPoliJobsProposalIdPut(
		{
			poliJobsProposalId,
			poliJobsProposalCandidateFiltersDTO,
		}
	);
};

export const updatePoliJobsProposalInfo = async (
	id: string,
	poliJobsProposalAddUpdateDTO?: PoliJobsProposalAddUpdateDTO
) => {
	await AuthPoliJobsProposalApiFactory().apiPoliJobsProposalUpdateIdPut({
		id,
		poliJobsProposalAddUpdateDTO,
	});
};

export const deletePoliJobsProposal = async (id: string) => {
	await AuthPoliJobsProposalApiFactory().apiPoliJobsProposalDeleteIdDelete({
		id,
	});
};

export const uploadPoliJobsProposalJD = async (
	poliJobsProposalId: string,
	file: Blob
) => {
	await AuthPoliJobsProposalApiFactory().apiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPost(
		{ poliJobsProposalId, file }
	);
};

export const downloadPoliJobsProposalJD = (poliJobsProposalId: string) => {
	return AuthPoliJobsProposalApiFactory().apiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGet(
		{
			poliJobsProposalId,
		}
	);
};

export const canApply = async (poliJobsProposalId: string) => {
	await AuthPoliJobsProposalApiFactory().apiPoliJobsProposalCanApplyPoliJobsProposalIdGet(
		{ poliJobsProposalId }
	);
};

export const applyPoliJobsProposal = async (poliJobsProposalId: string) => {
	await AuthPoliJobsProposalApiFactory().apiPoliJobsProposalApplyPoliJobsProposalIdPost(
		{ poliJobsProposalId }
	);
};
