import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
	isUserLogged,
	logout,
	safeAnd,
	useIsAdmin,
	useIsAdministrativeAccordsResponsible,
	useIsCompaniesFeedbackEnabled,
	useIsCompany,
	useIsDean,
	useIsDepartmentDirector,
	useIsFacultyAdmin,
	useIsFacultyContactsResponsible,
	useIsFeedbackAdmin,
	useIsInternshipAdmin,
	useIsNewsletterAdmin,
	useIsPhdStudentsFeedbackEnabled,
	useIsPoliJobsAdmin,
	useIsProfessor,
	useIsProfessorsFeedbackEnabled,
	useIsRector,
	useIsStudent,
	useIsStudentsFeedbackEnabled,
	useIsStudyProgramCoordinator,
	useIsUPBAdministrativeUser,
} from "../../utils/utilFunctions";
import { useQuery } from "react-query";
import { getAllCompanyCounters } from "../../Requests/company-requests";
import {
	getAllApplicationTypesCount,
	getAllCompanyCount,
	getDiplomaStudentsCount,
	getNotificationsNumber,
	getPracticeStudentsCount,
	getStudentsTypesCount,
} from "../../utils/reactQueriesConstants";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Components/Notifications/NotificationsUtils";
import { Routes } from "../../utils/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import {
	BellOutlined,
	LogoutOutlined,
	SettingOutlined,
	UserOutlined,
} from "@ant-design/icons";
import {
	getCountAllApplicationTypes,
	getCountDiplomaStudents,
	getCountPracticeStudents,
	getCountStudentsTypes,
} from "../../Requests/internship-requests";
import { getCountNotificationsNumber } from "../../Requests/notification-requests";
import { useFeatureFlags } from "../../utils/featureFlags";

interface Link {
	key: string;
	icon: React.ReactNode;
	label: string;
}

export const NavigationMenu = ({
	menuCollapsed,
}: {
	menuCollapsed?: boolean;
}) => {
	const { t, i18n } = useTranslation();
	const { pathname } = useLocation();
	const history = useHistory();
	const [links, setLinks] = useState<Link[]>([]);
	const [userLinks, setUserLinks] = useState<Link[]>([]);
	const { data: isStudentsFeedbackEnabled } = useIsStudentsFeedbackEnabled();
	const isCompany = useIsCompany();
	const isProfessor = useIsProfessor();
	const isStudent = useIsStudent();
	const isInternshipAdmin = useIsInternshipAdmin();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isDean = useIsDean();
	const isStudyProgramCoordinator = useIsStudyProgramCoordinator();
	const isAdmin = useIsAdmin();
	const isNewsletterAdmin = useIsNewsletterAdmin();
	const isUPBAdministrativeUser = useIsUPBAdministrativeUser();
	const isRector = useIsRector();
	const isAdministrativeAccordsResponsible =
		useIsAdministrativeAccordsResponsible();
	const isFacultyContactsResponsible = useIsFacultyContactsResponsible();
	const { data: isPhdStudentsFeedbackEnabled } =
		useIsPhdStudentsFeedbackEnabled();
	const { data: isProfessorsFeedbackEnabled } =
		useIsProfessorsFeedbackEnabled();
	const { data: isCompaniesFeedbackEnabled } = useIsCompaniesFeedbackEnabled();
	const isFeedbackAdmin = useIsFeedbackAdmin();
	const isPoliJobsAdmin = useIsPoliJobsAdmin();
	const {
		isAcademicUsersResearchInternshipEnabled,
		isCompaniesResearchInternshipEnabled,
		isAcademicUsersEventEnabled,
		isAcademicUsersJobEnabled,
		isAcademicUsersSummerSchoolEnabled,
		isAcademicUsersBachelorInternshipEnabled,
		isAcademicUsersMasterInternshipEnabled,
		isAcademicUsersScholarshipEnabled,
		isPoliJobsOffersEnabled,
	} = useFeatureFlags();

	const { data: notificationsNumber } = useQuery(
		[getNotificationsNumber],
		() => {
			if (isUserLogged()) return getCountNotificationsNumber();
		}
	);

	const { data: allApplicationTypes } = useQuery(
		[getAllApplicationTypesCount, isStudent],
		async () => (isStudent ? await getCountAllApplicationTypes() : null),
		{
			onError: () => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("header.applicationsCountError"),
					NOTIFICATION_TYPES.ERROR
				);
			},
		}
	);

	const { data: companyCounters } = useQuery(
		[getAllCompanyCount, isCompany],
		async () => (isCompany ? await getAllCompanyCounters() : null),
		{
			onError: () => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("header.companyCountersError"),
					NOTIFICATION_TYPES.ERROR
				);
			},
		}
	);

	const { data: studentsTypes } = useQuery(
		[getStudentsTypesCount, isCompany, isProfessor],
		async () =>
			isCompany || isProfessor ? await getCountStudentsTypes() : null,
		{
			onError: () => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("header.studentsCountError"),
					NOTIFICATION_TYPES.ERROR
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: practiceStudents } = useQuery(
		[getPracticeStudentsCount, isInternshipAdmin, isAdmin],
		async () =>
			isInternshipAdmin || isAdmin ? await getCountPracticeStudents() : null,
		{
			onError: () => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("header.studentsCountError"),
					NOTIFICATION_TYPES.ERROR
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: diplomaStudents } = useQuery(
		[
			getDiplomaStudentsCount,
			isDean,
			isDepartmentDirector,
			isStudyProgramCoordinator,
		],
		async () =>
			isDean || isDepartmentDirector || isStudyProgramCoordinator
				? await getCountDiplomaStudents()
				: null,
		{
			onError: () => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("header.studentsCountError"),
					NOTIFICATION_TYPES.ERROR
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const addLinkToSidenav = (
		route: string,
		icon: React.ReactNode,
		label: string
	) => {
		setLinks((prev) => [
			...prev,
			{
				key: route,
				icon: icon,
				label: label,
			},
		]);
	};

	const addUserLinkToSidenav = (
		route: string,
		icon: React.ReactNode,
		label: string
	) => {
		setUserLinks((prev) => [
			...prev,
			{
				key: route,
				icon: icon,
				label: label,
			},
		]);
	};

	useEffect(() => {
		setLinks([]);
		setUserLinks([]);
		if (!isUserLogged()) return;

		if (
			isCompany ||
			isProfessor ||
			isFacultyAdmin ||
			isRector ||
			isDean ||
			isInternshipAdmin ||
			isAdmin ||
			isStudyProgramCoordinator
		) {
			addLinkToSidenav(
				Routes.OFFERS,
				<FontAwesomeIcon icon={solid("briefcase")} />,
				t("header.internships")
			);
		}

		if (
			(isCompany && isCompaniesResearchInternshipEnabled) ||
			(isProfessor && isAcademicUsersResearchInternshipEnabled)
		) {
			addLinkToSidenav(
				Routes.PARTNERSHIPS,
				<FontAwesomeIcon icon={solid("handshake-angle")} />,
				t("header.researchOffers")
			);
		}

		if (isPoliJobsAdmin && isPoliJobsOffersEnabled) {
			addLinkToSidenav(
				Routes.POLIJOBS,
				<FontAwesomeIcon icon={solid("wallet")} />,
				t("header.poliJobsOffers")
			);
		}

		if (isStudent) {
			addLinkToSidenav(
				Routes.FACULTY_PROPOSALS,
				<FontAwesomeIcon icon={solid("school")} />,
				t("header.facultyInternships")
			);
		}

		if (isStudent) {
			addLinkToSidenav(
				Routes.COMPANY_PROPOSALS,
				<FontAwesomeIcon icon={solid("building")} />,
				t("header.companyInternships")
			);
		}

		if (isStudent && isAcademicUsersEventEnabled) {
			addLinkToSidenav(
				Routes.EVENTS,
				<FontAwesomeIcon icon={solid("calendar-check")} />,
				t("header.events")
			);
		}

		if (
			isCompany &&
			companyCounters?.practiceProtocols &&
			companyCounters.practiceProtocols > 0
		) {
			addLinkToSidenav(
				Routes.STUDENTS_TABLE,
				<FontAwesomeIcon icon={solid("users")} />,
				t("header.students")
			);
		}

		if (isCompany || isProfessor || isInternshipAdmin) {
			addLinkToSidenav(
				Routes.APPLICANTS,
				<FontAwesomeIcon icon={solid("user-tie")} />,
				t("header.applicants")
			);
		}

		if (
			isCompany &&
			studentsTypes?.trainees !== undefined &&
			studentsTypes.trainees > 0
		) {
			addLinkToSidenav(
				Routes.MY_STUDENTS,
				<FontAwesomeIcon icon={solid("users-line")} />,
				t("header.myStudents")
			);
		}

		if (
			safeAnd([
				!isUPBAdministrativeUser,
				!isAdmin,
				!isFacultyAdmin,
				!isDean,
				!isDepartmentDirector,
				!isStudent,
				!isCompany,
			])
		) {
			addLinkToSidenav(
				Routes.STUDENTS,
				<FontAwesomeIcon icon={solid("users")} />,
				t("header.students")
			);
		}

		if (
			(isDean || isDepartmentDirector || isStudyProgramCoordinator) &&
			diplomaStudents !== null &&
			diplomaStudents !== undefined &&
			diplomaStudents > 0 &&
			(isAcademicUsersBachelorInternshipEnabled ||
				isAcademicUsersMasterInternshipEnabled)
		) {
			addLinkToSidenav(
				Routes.DIPLOMA_STUDENTS,
				<FontAwesomeIcon icon={solid("graduation-cap")} />,
				t("header.diplomaStudents")
			);
		}

		if (
			(isInternshipAdmin || isAdmin) &&
			practiceStudents !== undefined &&
			practiceStudents !== null &&
			practiceStudents > 0
		) {
			addLinkToSidenav(
				Routes.PRACTICE_STUDENTS,
				<FontAwesomeIcon icon={solid("graduation-cap")} />,
				t("header.practiceStudents")
			);
		}

		if (
			isStudent &&
			allApplicationTypes?.allApplications !== undefined &&
			allApplicationTypes.allApplications > 0
		) {
			addLinkToSidenav(
				Routes.MY_INTERNSHIPS,
				<FontAwesomeIcon icon={solid("building-circle-check")} />,
				t("header.myInternships")
			);
		}

		if (
			isStudent &&
			allApplicationTypes?.scholarshipApplication !== undefined &&
			allApplicationTypes.scholarshipApplication > 0 &&
			isAcademicUsersScholarshipEnabled
		) {
			addLinkToSidenav(
				Routes.MY_SCHOLARSHIPS,
				<FontAwesomeIcon icon={solid("school-circle-check")} />,
				t("header.myScholarships")
			);
		}

		if (
			isStudent &&
			allApplicationTypes?.bachelorInternshipApplications !== undefined &&
			allApplicationTypes.bachelorInternshipApplications > 0 &&
			isAcademicUsersBachelorInternshipEnabled
		) {
			addLinkToSidenav(
				Routes.BACHELOR_THESIS,
				<FontAwesomeIcon icon={solid("file-contract")} />,
				t("header.myBachelorThesis")
			);
		}

		if (
			isStudent &&
			allApplicationTypes?.masterInternshipApplications !== undefined &&
			allApplicationTypes.masterInternshipApplications > 0 &&
			isAcademicUsersMasterInternshipEnabled
		) {
			addLinkToSidenav(
				Routes.RESEARCH_THESIS,
				<FontAwesomeIcon icon={solid("file-waveform")} />,
				t("header.myResearchThesis")
			);
		}

		if (
			isStudent &&
			allApplicationTypes?.internshipApplications !== undefined &&
			allApplicationTypes.internshipApplications > 0
		) {
			addLinkToSidenav(
				Routes.INTERNSHIP,
				<FontAwesomeIcon icon={solid("file-pen")} />,
				t("header.myInternship")
			);
		}

		if (
			isStudent &&
			allApplicationTypes?.otherApplications !== undefined &&
			allApplicationTypes.otherApplications > 0 &&
			(isAcademicUsersEventEnabled ||
				isAcademicUsersJobEnabled ||
				isAcademicUsersSummerSchoolEnabled ||
				isAcademicUsersResearchInternshipEnabled)
		) {
			addLinkToSidenav(
				Routes.OTHER_POS,
				<FontAwesomeIcon icon={solid("file-lines")} />,
				t("header.otherPositions")
			);
		}

		if (
			isStudent &&
			allApplicationTypes?.oldApplications !== undefined &&
			allApplicationTypes.oldApplications > 0
		) {
			addLinkToSidenav(
				Routes.OLD_POS,
				<FontAwesomeIcon icon={solid("clock-rotate-left")} />,
				t("header.previousEnrollments")
			);
		}

		if (
			isAdmin ||
			isFacultyAdmin ||
			isRector ||
			isDepartmentDirector ||
			isDean
		) {
			addLinkToSidenav(
				Routes.USERS,
				<FontAwesomeIcon icon={solid("user-group")} />,
				t("header.users")
			);
		}

		if (isCompany || isUPBAdministrativeUser) {
			addLinkToSidenav(
				Routes.UNIVERSITY_ACCORDS,
				<FontAwesomeIcon icon={solid("file-signature")} />,
				t("header.universityAccords")
			);
		}

		if (
			isDean ||
			isDepartmentDirector ||
			isStudyProgramCoordinator ||
			isFacultyAdmin
		) {
			addLinkToSidenav(
				Routes.REPORTS,
				<FontAwesomeIcon icon={solid("chart-simple")} />,
				t("header.reports")
			);
		}

		if (
			isDean ||
			isFacultyContactsResponsible ||
			isRector ||
			isAdministrativeAccordsResponsible
		) {
			addLinkToSidenav(
				Routes.CONTACTS,
				<FontAwesomeIcon icon={solid("city")} />,
				t("header.companies")
			);
		}

		if (isAdmin) {
			addLinkToSidenav(
				Routes.ADMIN_ENROLLMENTS,
				<FontAwesomeIcon icon={solid("user-group")} />,
				t("header.adminEnrollments")
			);
		}

		if (isNewsletterAdmin) {
			addLinkToSidenav(
				Routes.NEWSLETTER,
				<FontAwesomeIcon icon={solid("newspaper")} />,
				t("header.newsletter")
			);
		}

		if (isStudentsFeedbackEnabled?.value) {
			addLinkToSidenav(
				Routes.STUDENTS_FEEDBACK,
				<FontAwesomeIcon icon={solid("newspaper")} />,
				t("header.feedback")
			);
		}

		if (isPhdStudentsFeedbackEnabled?.value) {
			addLinkToSidenav(
				Routes.PHD_FEEDBACK,
				<FontAwesomeIcon icon={solid("newspaper")} />,
				t("header.feedback")
			);
		}

		if (isProfessorsFeedbackEnabled?.value) {
			addLinkToSidenav(
				Routes.PROFESSOR_FEEDBACK,
				<FontAwesomeIcon icon={solid("newspaper")} />,
				t("header.feedback")
			);
		}

		if (isCompaniesFeedbackEnabled?.value) {
			addLinkToSidenav(
				Routes.COMPANIES_FEEDBACK,
				<FontAwesomeIcon icon={solid("newspaper")} />,
				t("header.feedback")
			);
		}

		if (isFeedbackAdmin) {
			addLinkToSidenav(
				Routes.FEEDBACK_FLAGS,
				<FontAwesomeIcon icon={solid("newspaper")} />,
				t("header.feedbackFlags")
			);
		}

		if (isAdmin) {
			addLinkToSidenav(
				Routes.ADMIN_FLAGS,
				<FontAwesomeIcon icon={solid("newspaper")} />,
				t("header.adminFeedbackFlags")
			);
		}

		// USER LINKS
		if (isDean) {
			addUserLinkToSidenav(
				Routes.SETTINGS,
				<SettingOutlined />,
				t("header.settings")
			);
		}

		addUserLinkToSidenav(
			Routes.NOTIFICATIONS,

			<div className="relative">
				{notificationsNumber && notificationsNumber > 0 && (
					<div
						className={`absolute -left-4 size-5 rounded-full text-white text-sm flex items-center justify-center bg-error ${
							menuCollapsed ? "-top-0" : "-top-2"
						}`}
					>
						{notificationsNumber}
					</div>
				)}
				<BellOutlined />
			</div>,
			t("header.notifications")
		);

		if (isStudent) {
			addUserLinkToSidenav(
				Routes.STUDENT_PROFILE,
				<UserOutlined />,
				t("header.profile")
			);
		} else {
			addUserLinkToSidenav(
				Routes.PROFILE,
				<UserOutlined />,
				t("header.myAccount")
			);
		}
		addUserLinkToSidenav(
			Routes.HOME,
			<div onClick={() => logout(!isCompany, i18n)} title={t("header.signout")}>
				<LogoutOutlined />
			</div>,
			t("header.signout")
		);
	}, [
		menuCollapsed,
		notificationsNumber,
		allApplicationTypes?.allApplications,
		allApplicationTypes?.bachelorInternshipApplications,
		allApplicationTypes?.internshipApplications,
		allApplicationTypes?.masterInternshipApplications,
		allApplicationTypes?.oldApplications,
		allApplicationTypes?.otherApplications,
		allApplicationTypes?.scholarshipApplication,
		companyCounters?.practiceProtocols,
		diplomaStudents,
		i18n,
		isAdmin,
		isAdministrativeAccordsResponsible,
		isCompaniesFeedbackEnabled?.value,
		isCompany,
		isDean,
		isDepartmentDirector,
		isFacultyAdmin,
		isFacultyContactsResponsible,
		isFeedbackAdmin,
		isInternshipAdmin,
		isNewsletterAdmin,
		isPhdStudentsFeedbackEnabled?.value,
		isProfessor,
		isProfessorsFeedbackEnabled?.value,
		isRector,
		isStudent,
		isStudentsFeedbackEnabled?.value,
		isStudyProgramCoordinator,
		isUPBAdministrativeUser,
		practiceStudents,
		studentsTypes?.trainees,
		t,
		isCompaniesResearchInternshipEnabled,
		isAcademicUsersResearchInternshipEnabled,
		isAcademicUsersEventEnabled,
		isAcademicUsersBachelorInternshipEnabled,
		isAcademicUsersMasterInternshipEnabled,
		isAcademicUsersScholarshipEnabled,
		isAcademicUsersJobEnabled,
		isAcademicUsersSummerSchoolEnabled,
	]);

	const handleRoute = ({ key }: { key: string }) => {
		history.push(key);
	};

	return (
		<>
			<Menu
				className="my-auto"
				theme="light"
				mode="inline"
				defaultSelectedKeys={[pathname]}
				onClick={handleRoute}
				items={links}
			/>

			<Menu
				theme="light"
				mode="inline"
				defaultSelectedKeys={[pathname]}
				onClick={handleRoute}
				items={userLinks}
			/>
		</>
	);
};
