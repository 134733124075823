/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Role, RoleFromJSON, RoleFromJSONTyped, RoleToJSON } from "./Role";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface SimpleViewStudentDTO
 */
export interface SimpleViewStudentDTO {
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly name?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly cursId?: number;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly email?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly description?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly phone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly cvUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly cvFileName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly linkedinUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly gitUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly siteUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly researchField?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly image?: string | null;
	/**
	 *
	 * @type {Array<Role>}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly roles?: Array<Role> | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly departmentNameRo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly departmentNameEn?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly facultyNameRo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly facultyNameEn?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SimpleViewStudentDTO
	 */
	readonly studyProgram?: string | null;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof SimpleViewStudentDTO
	 */
	year?: StudentYearEnum;
	/**
	 *
	 * @type {boolean}
	 * @memberof SimpleViewStudentDTO
	 */
	hasOverdueL2Practice?: boolean;
	/**
	 *
	 * @type {boolean}
	 * @memberof SimpleViewStudentDTO
	 */
	hasOverdueL3Practice?: boolean;
}

export function SimpleViewStudentDTOFromJSON(json: any): SimpleViewStudentDTO {
	return SimpleViewStudentDTOFromJSONTyped(json, false);
}

export function SimpleViewStudentDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): SimpleViewStudentDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		name: !exists(json, "name") ? undefined : json["name"],
		cursId: !exists(json, "cursId") ? undefined : json["cursId"],
		email: !exists(json, "email") ? undefined : json["email"],
		description: !exists(json, "description") ? undefined : json["description"],
		phone: !exists(json, "phone") ? undefined : json["phone"],
		cvUrl: !exists(json, "cvUrl") ? undefined : json["cvUrl"],
		cvFileName: !exists(json, "cvFileName") ? undefined : json["cvFileName"],
		linkedinUrl: !exists(json, "linkedinUrl") ? undefined : json["linkedinUrl"],
		gitUrl: !exists(json, "gitUrl") ? undefined : json["gitUrl"],
		siteUrl: !exists(json, "siteUrl") ? undefined : json["siteUrl"],
		researchField: !exists(json, "researchField")
			? undefined
			: json["researchField"],
		image: !exists(json, "image") ? undefined : json["image"],
		roles: !exists(json, "roles")
			? undefined
			: json["roles"] === null
			? null
			: (json["roles"] as Array<any>).map(RoleFromJSON),
		departmentNameRo: !exists(json, "departmentNameRo")
			? undefined
			: json["departmentNameRo"],
		departmentNameEn: !exists(json, "departmentNameEn")
			? undefined
			: json["departmentNameEn"],
		facultyNameRo: !exists(json, "facultyNameRo")
			? undefined
			: json["facultyNameRo"],
		facultyNameEn: !exists(json, "facultyNameEn")
			? undefined
			: json["facultyNameEn"],
		studyProgram: !exists(json, "studyProgram")
			? undefined
			: json["studyProgram"],
		year: !exists(json, "year")
			? undefined
			: StudentYearEnumFromJSON(json["year"]),
		hasOverdueL2Practice: !exists(json, "hasOverdueL2Practice")
			? undefined
			: json["hasOverdueL2Practice"],
		hasOverdueL3Practice: !exists(json, "hasOverdueL3Practice")
			? undefined
			: json["hasOverdueL3Practice"],
	};
}

export function SimpleViewStudentDTOToJSON(
	value?: SimpleViewStudentDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		year: StudentYearEnumToJSON(value.year),
		hasOverdueL2Practice: value.hasOverdueL2Practice,
		hasOverdueL3Practice: value.hasOverdueL3Practice,
	};
}
