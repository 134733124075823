import {
	ChangePasswordDTO,
	CompanyPasswordResetDTO,
	CompanyUserAddUpdateDTO,
	CompanyUsersApi,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const CompanyUsersApiFactory = () =>
	new CompanyUsersApi(getAuthConfiguration());

//de testat toate
export const addCompanyUsers = async (
	companyUserAddUpdateDTO: CompanyUserAddUpdateDTO
) => {
	await CompanyUsersApiFactory().apiCompanyUsersAddPost({
		companyUserAddUpdateDTO,
	});
};

export const addCompanyUsersAdmin = async (
	companyUserAddUpdateDTO: CompanyUserAddUpdateDTO,
	id: string
) => {
	await CompanyUsersApiFactory().apiCompanyUsersAddAdminIdPost({
		companyUserAddUpdateDTO,
		id,
	});
};

export const addCompanyContactUsers = async (
	companyUserAddUpdateDTO: CompanyUserAddUpdateDTO,
	companyId: string
) => {
	await CompanyUsersApiFactory().apiCompanyUsersAddContactCompanyIdPost({
		companyUserAddUpdateDTO,
		companyId,
	});
};

export const deleteCompanyUser = async (id: string) => {
	await CompanyUsersApiFactory().apiCompanyUsersDeleteIdDelete({ id });
};

export const deleteCompanyUserAdmin = async (id: string, companyId?: string) => {
	await CompanyUsersApiFactory().apiCompanyUsersDeleteAdminIdDelete({ id, companyId });
};

export const updateCompanyUser = async (
	id: string,
	companyUserAddUpdateDTO: CompanyUserAddUpdateDTO
) => {
	await CompanyUsersApiFactory().apiCompanyUsersUpdateIdPut({
		id,
		companyUserAddUpdateDTO,
	});
};

export const updateCompanyUserAdmin = async (
	id: string,
	companyUserAddUpdateDTO: CompanyUserAddUpdateDTO
) => {
	await CompanyUsersApiFactory().apiCompanyUsersUpdateAdminIdPut({
		id,
		companyUserAddUpdateDTO,
	});
};

export const getAllCompanyUsers = (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	companyId: string | undefined = undefined
) => {
	return CompanyUsersApiFactory().apiCompanyUsersGetAllGet({
		namePattern,
		companyId,
		page,
		pageSize,
	});
};

export interface CompanyUsersProps {
	search?: string; //namePattern
	page?: number; //page
	pageSize?: number; //pageSize
	companyId?: string;
}

export const getAllCompanyUsersV2 = (props: CompanyUsersProps) => {
	const { search: namePattern, companyId, page, pageSize } = props;

	return CompanyUsersApiFactory().apiCompanyUsersGetAllGet({
		namePattern,
		companyId,
		page,
		pageSize,
	});
};

export const listAllCompanyUsers = () => {
	return CompanyUsersApiFactory().apiCompanyUsersListAllGet();
};

export const listAllCompanyUsersForCompany = (companyId: string) => {
	return CompanyUsersApiFactory().apiCompanyUsersListAllForCompanyGet({
		companyId,
	});
};

export const getCompanyUserById = (id: string) => {
	return CompanyUsersApiFactory().apiCompanyUsersGetByIdIdGet({ id });
};

export const makeRepresentative = async (id: string) => {
	await CompanyUsersApiFactory().apiCompanyUsersChangeRepresentativeUserIdPut({
		id,
	});
};

export const makeRepresentativeAdmin = async (id: string, companyId?: string) => {
	await CompanyUsersApiFactory().apiCompanyUsersChangeRepresentativeUserAdminIdPut({
		id,
		companyId
	});
};

export const toggleCompanyUserNotifications = async (id: string) => {
	await CompanyUsersApiFactory().apiCompanyUsersToggleNotificationsIdPut({
		id,
	});
};

export const toggleAdminRole = async (id: string) => {
	await CompanyUsersApiFactory().apiCompanyUsersToggleAdminRoleIdPut({ id });
};

export const changeCompanyUserPassword = async (
	changePasswordDTO: ChangePasswordDTO
) => {
	await CompanyUsersApiFactory().apiCompanyUsersChangePasswordPost({
		changePasswordDTO,
	});
};

export const sendResetPasswordLink = async (
	companyPasswordResetDTO: CompanyPasswordResetDTO
) => {
	await CompanyUsersApiFactory().apiCompanyUsersSendResetPasswordLinkPost({
		companyPasswordResetDTO,
	});
};

export const resetCompanyUserPassword = async (
	token: string,
	changePasswordDTO: ChangePasswordDTO
) => {
	await CompanyUsersApiFactory().apiCompanyUsersResetPasswordTokenPost({
		token,
		changePasswordDTO,
	});
};

export const activateAccountForContactUser = async (id: string) => {
	await CompanyUsersApiFactory().apiCompanyUsersActivateAccountIdPut({ id });
};
