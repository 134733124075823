/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	Company,
	CompanyFromJSON,
	CompanyFromJSONTyped,
	CompanyToJSON,
} from "./Company";
import {
	PoliJobsPotentialCandidate,
	PoliJobsPotentialCandidateFromJSON,
	PoliJobsPotentialCandidateFromJSONTyped,
	PoliJobsPotentialCandidateToJSON,
} from "./PoliJobsPotentialCandidate";
import {
	PoliJobsProposalEnrollment,
	PoliJobsProposalEnrollmentFromJSON,
	PoliJobsProposalEnrollmentFromJSONTyped,
	PoliJobsProposalEnrollmentToJSON,
} from "./PoliJobsProposalEnrollment";

/**
 *
 * @export
 * @interface PoliJobsProposal
 */
export interface PoliJobsProposal {
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposal
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposal
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposal
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposal
	 */
	name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposal
	 */
	jobDescriptionFileName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposal
	 */
	jobDescriptionText?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposal
	 */
	jobDescriptionTokens?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposal
	 */
	jobDescriptionLang?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposal
	 */
	url?: string | null;
	/**
	 *
	 * @type {boolean}
	 * @memberof PoliJobsProposal
	 */
	isArchived?: boolean;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposal
	 */
	availableFrom?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposal
	 */
	availableTo?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposal
	 */
	startingDate?: Date;
	/**
	 *
	 * @type {number}
	 * @memberof PoliJobsProposal
	 */
	numberOfPositions?: number;
	/**
	 *
	 * @type {number}
	 * @memberof PoliJobsProposal
	 */
	availableNumberOfPositions?: number;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposal
	 */
	publisherId?: string;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof PoliJobsProposal
	 */
	publisher?: AcademicUser;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposal
	 */
	companyId?: string;
	/**
	 *
	 * @type {Company}
	 * @memberof PoliJobsProposal
	 */
	company?: Company;
	/**
	 *
	 * @type {Array<PoliJobsProposalEnrollment>}
	 * @memberof PoliJobsProposal
	 */
	enrollments?: Array<PoliJobsProposalEnrollment> | null;
	/**
	 *
	 * @type {Array<PoliJobsPotentialCandidate>}
	 * @memberof PoliJobsProposal
	 */
	potentialCandidates?: Array<PoliJobsPotentialCandidate> | null;
}

export function PoliJobsProposalFromJSON(json: any): PoliJobsProposal {
	return PoliJobsProposalFromJSONTyped(json, false);
}

export function PoliJobsProposalFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsProposal {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		name: !exists(json, "name") ? undefined : json["name"],
		jobDescriptionFileName: !exists(json, "jobDescriptionFileName")
			? undefined
			: json["jobDescriptionFileName"],
		jobDescriptionText: !exists(json, "jobDescriptionText")
			? undefined
			: json["jobDescriptionText"],
		jobDescriptionTokens: !exists(json, "jobDescriptionTokens")
			? undefined
			: json["jobDescriptionTokens"],
		jobDescriptionLang: !exists(json, "jobDescriptionLang")
			? undefined
			: json["jobDescriptionLang"],
		url: !exists(json, "url") ? undefined : json["url"],
		isArchived: !exists(json, "isArchived") ? undefined : json["isArchived"],
		availableFrom: !exists(json, "availableFrom")
			? undefined
			: new Date(json["availableFrom"]),
		availableTo: !exists(json, "availableTo")
			? undefined
			: new Date(json["availableTo"]),
		startingDate: !exists(json, "startingDate")
			? undefined
			: new Date(json["startingDate"]),
		numberOfPositions: !exists(json, "numberOfPositions")
			? undefined
			: json["numberOfPositions"],
		availableNumberOfPositions: !exists(json, "availableNumberOfPositions")
			? undefined
			: json["availableNumberOfPositions"],
		publisherId: !exists(json, "publisherId") ? undefined : json["publisherId"],
		publisher: !exists(json, "publisher")
			? undefined
			: AcademicUserFromJSON(json["publisher"]),
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		company: !exists(json, "company")
			? undefined
			: CompanyFromJSON(json["company"]),
		enrollments: !exists(json, "enrollments")
			? undefined
			: json["enrollments"] === null
			? null
			: (json["enrollments"] as Array<any>).map(
					PoliJobsProposalEnrollmentFromJSON
			  ),
		potentialCandidates: !exists(json, "potentialCandidates")
			? undefined
			: json["potentialCandidates"] === null
			? null
			: (json["potentialCandidates"] as Array<any>).map(
					PoliJobsPotentialCandidateFromJSON
			  ),
	};
}

export function PoliJobsProposalToJSON(value?: PoliJobsProposal | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		name: value.name,
		jobDescriptionFileName: value.jobDescriptionFileName,
		jobDescriptionText: value.jobDescriptionText,
		jobDescriptionTokens: value.jobDescriptionTokens,
		jobDescriptionLang: value.jobDescriptionLang,
		url: value.url,
		isArchived: value.isArchived,
		availableFrom:
			value.availableFrom === undefined
				? undefined
				: value.availableFrom.toISOString(),
		availableTo:
			value.availableTo === undefined
				? undefined
				: value.availableTo.toISOString(),
		startingDate:
			value.startingDate === undefined
				? undefined
				: value.startingDate.toISOString(),
		numberOfPositions: value.numberOfPositions,
		availableNumberOfPositions: value.availableNumberOfPositions,
		publisherId: value.publisherId,
		publisher: AcademicUserToJSON(value.publisher),
		companyId: value.companyId,
		company: CompanyToJSON(value.company),
		enrollments:
			value.enrollments === undefined
				? undefined
				: value.enrollments === null
				? null
				: (value.enrollments as Array<any>).map(
						PoliJobsProposalEnrollmentToJSON
				  ),
		potentialCandidates:
			value.potentialCandidates === undefined
				? undefined
				: value.potentialCandidates === null
				? null
				: (value.potentialCandidates as Array<any>).map(
						PoliJobsPotentialCandidateToJSON
				  ),
	};
}
